import { CONST } from '../utils/data-config';
import { globalConfig } from '../data-config-global';
import { DOMESTIC_SHIPMENT_RANGE } from '../constants';

const ENV_DEV = process.env.NODE_ENV === 'development';
export const setItemDimensionParamByFarmApiForMax = (
  stateObject,
  stateObjectItemParam,
  farmApiProduct,
  farmApiProductPieceParam,
  farmApiProductShipmentParam,
) => {
  const pieceValue = farmApiProduct.piece[farmApiProductPieceParam];
  const shipmentValue = farmApiProduct.shipment[farmApiProductShipmentParam];
  const stateValue = stateObject[stateObjectItemParam];

  if (!isValidDimension(pieceValue) && !isValidDimension(shipmentValue)) {
    return;
  }

  const validPieceValue = isValidDimension(pieceValue) ? pieceValue : -Infinity;
  const validShipmentValue = isValidDimension(shipmentValue) ? shipmentValue : -Infinity;
  const validStateValue = isValidDimension(stateValue) ? stateValue : -Infinity;

  const minValue = Math.max(validPieceValue, validShipmentValue, validStateValue);

  if (minValue !== -Infinity) {
    stateObject[stateObjectItemParam] = minValue;
  }
};

// setItemDimensionParamByFarmApiForMin(stateObject, 'min_item_width', farmApiProduct, 'widthMin', 'widthMin');
export const setItemDimensionParamByFarmApiForMin = (
  stateObject,
  stateObjectItemParam,
  farmApiProduct,
  farmApiProductPieceParam,
  farmApiProductShipmentParam,
) => {
  const pieceValue = farmApiProduct.piece[farmApiProductPieceParam];
  const shipmentValue = farmApiProduct.shipment[farmApiProductShipmentParam];
  const stateValue = stateObject[stateObjectItemParam];

  if (!isValidDimension(pieceValue) && !isValidDimension(shipmentValue)) {
    return;
  }

  const validPieceValue = isValidDimension(pieceValue) ? pieceValue : Infinity;
  const validShipmentValue = isValidDimension(shipmentValue) ? shipmentValue : Infinity;
  const validStateValue = isValidDimension(stateValue) ? stateValue : Infinity;

  const minValue = Math.min(validPieceValue, validShipmentValue, validStateValue);

  if (minValue !== Infinity) {
    stateObject[stateObjectItemParam] = minValue;
  }
};
/**
 * Replace it by @link setItemDimensionParamByFarmApiForMax method.
 * (We shouldn't change the current logic even there is a bug.)
 */
export const setItemDimensionParamByFarmApiForMaxLegacy = (
  stateObject,
  stateObjectItemParam,
  farmApiProduct,
  farmApiProductShipmentParam,
) => {
  if (
    farmApiProduct.shipment[farmApiProductShipmentParam] &&
    farmApiProduct.shipment[farmApiProductShipmentParam] < stateObject[stateObjectItemParam]
  )
    stateObject[stateObjectItemParam] = farmApiProduct.shipment[farmApiProductShipmentParam];
};

/**
 * Replace it by @link setItemDimensionParamByFarmApiForMin method.
 * (We shouldn't change the current logic even there is a bug.)
 */
export const setItemDimensionParamByFarmApiForMinLegacy = (
  stateObject,
  stateObjectItemParam,
  farmApiProduct,
  farmApiProductShipmentParam,
) => {
  if (
    farmApiProduct.shipment[farmApiProductShipmentParam] &&
    farmApiProduct.shipment[farmApiProductShipmentParam] < stateObject[stateObjectItemParam]
  )
    stateObject[stateObjectItemParam] = farmApiProduct.shipment[farmApiProductShipmentParam];
};

export const setTotalDimensionParamByFarmApiForMin = (
  stateObject,
  stateObjectTotalParam,
  farmApiProduct,
  farmApiProductPieceParam,
  farmApiProductShipmentParam,
) => {
  const shipmentValue = farmApiProduct.shipment[farmApiProductShipmentParam] ?? 0;
  const stateValue = stateObject[stateObjectTotalParam] ?? Infinity;

  if (shipmentValue < stateValue && shipmentValue >= 0) {
    stateObject[stateObjectTotalParam] = shipmentValue;
  }
};

export const setTotalDimensionParamByFarmApiForMax = (
  stateObject,
  stateObjectTotalParam,
  farmApiProduct,
  farmApiProductPieceParam,
  farmApiProductShipmentParam,
) => {
  if (!farmApiProduct.shipment[farmApiProductShipmentParam] && !farmApiProduct.piece[farmApiProductPieceParam]) {
    if (process.env.REACT_APP_VERBOSE_LOGS?.toLowerCase() === 'true' && ENV_DEV) {
      console.log(
        "Total Dimension: Missing FarmApi validation parameters.[Product code='" +
          farmApiProduct.code +
          "', piece param = '" +
          farmApiProductPieceParam +
          "', shipment param = '" +
          farmApiProductShipmentParam +
          "']",
      );
    }
    return;
  }
  if (stateObject[stateObjectTotalParam]) {
    if (farmApiProduct.shipment[farmApiProductShipmentParam]) {
      if (farmApiProduct.shipment[farmApiProductShipmentParam] > stateObject[stateObjectTotalParam]) {
        stateObject[stateObjectTotalParam] = farmApiProduct.shipment[farmApiProductShipmentParam];
      }
    } else {
      if (farmApiProduct.piece[farmApiProductPieceParam]) {
        if (farmApiProduct.piece[farmApiProductPieceParam] > stateObject[stateObjectTotalParam]) {
          stateObject[stateObjectTotalParam] = farmApiProduct.piece[farmApiProductPieceParam];
        }
      }
    }
  } else {
    if (farmApiProduct.shipment[farmApiProductShipmentParam]) {
      stateObject[stateObjectTotalParam] = farmApiProduct.shipment[farmApiProductShipmentParam];
    } else {
      if (farmApiProduct.piece[farmApiProductPieceParam]) {
        stateObject[stateObjectTotalParam] = farmApiProduct.piece[farmApiProductPieceParam];
      }
    }
  }
};

/**
 * Fills missing params from FarmApi.
 * */
export const fillMissingParams = (stateObject, params, config) => {
  if (params) {
    params.forEach(param => {
      if (!stateObject[param]) {
        stateObject[param] = config.dimensions[param];
      }
    });
  }
};

export const calculateLoadingMeterMax = chargeableWeightMax => {
  return (chargeableWeightMax / CONST.CHARGEABLE_WEIGHT_DIVISOR).toFixedDown(
    globalConfig.shipmentInputs.loadingMeter.maxDecimals,
  );
};
export const calculatePerPalletPlaceMax = chargeableWeightMax => {
  return (chargeableWeightMax / CONST.PER_PALLET_PLACE_DIVISOR).toFixedDown(
    globalConfig.shipmentInputs.palletPlace.maxDecimals,
  );
};
export const setLoadingMeterAndPalletPlacesValues = chargeableWeightMax => {
  return {
    loadingMetreMin: 0,
    loadingMetreMax: calculateLoadingMeterMax(chargeableWeightMax),
    palletPlaceMin: 0,
    palletPlaceMax: calculatePerPalletPlaceMax(chargeableWeightMax),
  };
};

//initialization without any legacy hit
export const dimensionParams = [
  'min_item_quantity',
  'max_item_quantity',
  'min_item_weight',
  'max_item_weight',
  'min_item_volume',
  'max_item_volume',
  'min_item_length',
  'max_item_length',
  'min_item_width',
  'max_item_width',
  'min_item_height',
  'max_item_height',
  'min_item_palletPlace',
  'max_item_palletPlace',
  'min_item_loading_meter',
  'max_item_loading_meter',
  'min_item_chargeableWeight',
  'max_item_chargeableWeight',

  // TOTAL DIMENSIONS:
  'min_total_quantity',
  'max_total_quantity',
  'min_total_volume',
  'max_total_volume',
  'min_shipment_weight',
  'max_shipment_weight',
  'min_total_loading_meter',
  'max_total_loading_meter',
  'min_total_chargeableWeight',
  'max_total_chargeableWeight',
  'min_total_palletPlace',
  'max_total_palletPlace',
];

/**
 * FarmApi validations with a liberal approach - loosen boundaries according to products' specific validations.
 **/
export const setShipmentDimensionsForProductShowAll = (routeState, config, products) => {
  let shipmentRowsNumberMax = config.maxAllowedShipmentDetailsRows ?? 0;
  let farmApiValidationRulesStateObject = {};

  const isDomestic = routeState.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE;
  // to prevent readOnly error as there is direct property mutation
  const productsCopy = structuredClone(products);

  productsCopy
    .filter(product => product.isDomestic === isDomestic)
    .forEach(product => {
      //loosen the boundaries according to more extreme values
      if (config.products_require_ldm_calculation.indexOf(product.code) !== -1) {
        product.piece = Object.assign(
          {},
          product.piece,
          setLoadingMeterAndPalletPlacesValues(product.piece.chargeableWeightMax),
        );
      }

      // BASE PRODUCT RULES
      if (product.shipmentRowsNumberMax > shipmentRowsNumberMax) {
        shipmentRowsNumberMax = product.shipmentRowsNumberMax;
      }
      commonFarmApiItemDimensions(farmApiValidationRulesStateObject, product);
      commonFarmApiTotalDimensions(farmApiValidationRulesStateObject, product);

      // COUNTRY EXCEPTIONS
      const countrySpecification = product?.rulesForCountryAndDeliveryTypes?.filter(
        countryRule =>
          countryRule?.country?.countryCode?.toLowerCase() === routeState?.deliveryCountry?.value?.toLowerCase(),
      )?.[0];

      if (countrySpecification) {
        commonFarmApiItemDimensions(farmApiValidationRulesStateObject, countrySpecification);
        commonFarmApiTotalDimensions(farmApiValidationRulesStateObject, countrySpecification);
      }
    });

  fillMissingParams(farmApiValidationRulesStateObject, dimensionParams, config);
  return [farmApiValidationRulesStateObject, shipmentRowsNumberMax];
};

// prettier-ignore
export const commonFarmApiItemDimensions = (stateObject, farmApiProduct) => {
  // DIMENSIONS PER ITEM:
  setItemDimensionParamByFarmApiForMax(stateObject,'max_item_chargeableWeight',farmApiProduct,'chargeableWeightMax','chargeableWeightMax');
  setItemDimensionParamByFarmApiForMax(stateObject, 'max_item_height', farmApiProduct, 'heightMax', 'heightMax');
  setItemDimensionParamByFarmApiForMax(stateObject, 'max_item_length', farmApiProduct, 'lengthMax', 'lengthMax');
  setItemDimensionParamByFarmApiForMax(stateObject, 'max_item_loading_meter', farmApiProduct, 'loadingMetreMax', 'loadingMetreMax',);
  setItemDimensionParamByFarmApiForMax(stateObject, 'max_item_palletPlace', farmApiProduct, 'palletPlaceMax', 'palletPlaceMax',);
  setItemDimensionParamByFarmApiForMax(stateObject, 'max_item_quantity', farmApiProduct, 'numberOfPiecesMax', 'numberOfPiecesMax',);
  setItemDimensionParamByFarmApiForMax(stateObject, 'max_item_volume', farmApiProduct, 'volumeMax', 'volumeMax');
  setItemDimensionParamByFarmApiForMax(stateObject, 'max_item_weight', farmApiProduct, 'actualWeightMax', 'actualWeightMax',);
  setItemDimensionParamByFarmApiForMin(stateObject, 'min_item_chargeableWeight', farmApiProduct, 'chargeableWeightMin', 'chargeableWeightMin',);
  setItemDimensionParamByFarmApiForMin(stateObject, 'min_item_height', farmApiProduct, 'heightMin', 'heightMin');
  setItemDimensionParamByFarmApiForMin(stateObject, 'min_item_length', farmApiProduct, 'lengthMin', 'lengthMin');
  setItemDimensionParamByFarmApiForMin(stateObject, 'min_item_loading_meter', farmApiProduct, 'loadingMetreMin', 'loadingMetreMin',);
  setItemDimensionParamByFarmApiForMin(stateObject, 'min_item_palletPlace', farmApiProduct, 'palletPlaceMin', 'palletPlaceMin',);
  setItemDimensionParamByFarmApiForMin(stateObject, 'min_item_quantity', farmApiProduct, 'numberOfPiecesMin', 'numberOfPiecesMin',);
  setItemDimensionParamByFarmApiForMin(stateObject, 'min_item_volume', farmApiProduct, 'volumeMin', 'volumeMin');
  setItemDimensionParamByFarmApiForMin(stateObject, 'min_item_weight', farmApiProduct, 'actualWeightMin', 'actualWeightMin',);
  setItemDimensionParamByFarmApiForMin(stateObject, 'min_item_width', farmApiProduct, 'widthMin', 'widthMin');
  setItemDimensionParamByFarmApiForMax(stateObject, 'max_item_width', farmApiProduct, 'widthMax', 'widthMax');
  setItemDimensionParamByFarmApiForMax(stateObject, 'force_none_stackable_height', farmApiProduct, 'forceNoneStackableHeight', 'forceNoneStackableHeight');
  setItemDimensionParamByFarmApiForMin(stateObject, 'none_stackable_cw_from_volume_height', farmApiProduct, 'noneStackableCWFromVolumeHeight', 'noneStackableCWFromVolumeHeight');
  };

// prettier-ignore
export const commonFarmApiTotalDimensions = (stateObject, farmApiProduct) => {
  // TOTAL DIMENSIONS:
  setTotalDimensionParamByFarmApiForMax(stateObject, 'max_shipment_weight', farmApiProduct, 'actualWeightMax', 'actualWeightMax',);
  setTotalDimensionParamByFarmApiForMax(stateObject, 'max_total_chargeableWeight', farmApiProduct, 'chargeableWeightMax', 'chargeableWeightMax',);
  setTotalDimensionParamByFarmApiForMax(stateObject, 'max_total_loading_meter', farmApiProduct, 'loadingMetreMax', 'loadingMetreMax',);
  setTotalDimensionParamByFarmApiForMax(stateObject, 'max_total_palletPlace', farmApiProduct, 'palletPlaceMax', 'palletPlaceMax',);
  setTotalDimensionParamByFarmApiForMax(stateObject, 'max_total_quantity', farmApiProduct, 'numberOfPiecesMax', 'numberOfPiecesMax',);
  setTotalDimensionParamByFarmApiForMax(stateObject, 'max_total_volume', farmApiProduct, 'volumeMax', 'volumeMax');
  setTotalDimensionParamByFarmApiForMin(stateObject, 'min_total_chargeableWeight', farmApiProduct, 'chargeableWeightMin', 'chargeableWeightMin',);
  setTotalDimensionParamByFarmApiForMin(stateObject, 'min_total_loading_meter', farmApiProduct, 'loadingMetreMin', 'loadingMetreMin',);
  setTotalDimensionParamByFarmApiForMin(stateObject, 'min_total_palletPlace', farmApiProduct, 'palletPlaceMin', 'palletPlaceMin',);
  setTotalDimensionParamByFarmApiForMin(stateObject, 'min_total_quantity', farmApiProduct, 'numberOfPiecesMin', 'numberOfPiecesMin',);
  setTotalDimensionParamByFarmApiForMin(stateObject, 'min_total_volume', farmApiProduct, 'volumeMin', 'volumeMin');
  setTotalDimensionParamByFarmApiForMin(stateObject, 'min_shipment_weight', farmApiProduct, 'actualWeightMin', 'actualWeightMin',);
};

export const commonFarmApiItemDimensionsLegacy = (stateObject, product) => {
  // DIMENSIONS PER ITEM:
  setItemDimensionParamByFarmApiForMaxLegacy(stateObject, 'max_item_chargeableWeight', product, 'chargeableWeightMax');
  setItemDimensionParamByFarmApiForMaxLegacy(stateObject, 'max_item_height', product, 'heightMax');
  setItemDimensionParamByFarmApiForMaxLegacy(stateObject, 'max_item_length', product, 'lengthMax');
  setItemDimensionParamByFarmApiForMaxLegacy(stateObject, 'max_item_loading_meter', product, 'loadingMetreMax');
  setItemDimensionParamByFarmApiForMaxLegacy(stateObject, 'max_item_palletPlace', product, 'palletPlaceMax');
  setItemDimensionParamByFarmApiForMaxLegacy(stateObject, 'max_item_quantity', product, 'numberOfPiecesMax');
  setItemDimensionParamByFarmApiForMaxLegacy(stateObject, 'max_item_volume', product, 'volumeMax');
  setItemDimensionParamByFarmApiForMaxLegacy(stateObject, 'max_item_weight', product, 'actualWeightMax');
  setItemDimensionParamByFarmApiForMaxLegacy(stateObject, 'max_item_width', product, 'widthMax');
  setItemDimensionParamByFarmApiForMinLegacy(stateObject, 'min_item_chargeableWeight', product, 'chargeableWeightMin');
  setItemDimensionParamByFarmApiForMinLegacy(stateObject, 'min_item_height', product, 'heightMin');
  setItemDimensionParamByFarmApiForMinLegacy(stateObject, 'min_item_length', product, 'lengthMin');
  setItemDimensionParamByFarmApiForMinLegacy(stateObject, 'min_item_loading_meter', product, 'loadingMetreMin');
  setItemDimensionParamByFarmApiForMinLegacy(stateObject, 'min_item_palletPlace', product, 'palletPlaceMin');
  setItemDimensionParamByFarmApiForMinLegacy(stateObject, 'min_item_quantity', product, 'numberOfPiecesMin');
  setItemDimensionParamByFarmApiForMinLegacy(stateObject, 'min_item_volume', product, 'volumeMin');
  setItemDimensionParamByFarmApiForMinLegacy(stateObject, 'min_item_weight', product, 'actualWeightMin');
  setItemDimensionParamByFarmApiForMinLegacy(stateObject, 'min_item_width', product, 'widthMin');
};

/**
 * Validations with a restrictive approach.
 * If there is FarmApi validation values and restrict more than default (hardcoded part) values, FarmApi values are taken in account.
 **/
export const setShipmentDimensionsForProduct = (routeState, config, productCode) => {
  let stateObject = {};
  let farmapiProduct = {};
  let shipmentRowsNumberMax = config.maxAllowedShipmentDetailsRows;

  stateObject = {};

  farmapiProduct = JSON.parse(JSON.stringify(routeState.productSpecifications[productCode]));

  const countryDestinationRules = farmapiProduct.rulesForCountryAndDeliveryTypes.filter(countryRule => {
    return countryRule.country.countryCode.toLowerCase() === routeState.deliveryCountry.value.toLowerCase();
  });

  const mergedCountryRules = countryDestinationRules.reduce(
    (merged, current) => {
      Object.entries(current?.piece).forEach(([key, value]) => {
        merged.piece[key] = key.endsWith('Max')
          ? Math.max(value, merged.piece[key] ?? -Infinity)
          : Math.min(value, merged.piece[key] ?? Infinity);
      });
      Object.entries(current?.shipment).forEach(([key, value]) => {
        merged.shipment[key] = key.endsWith('Max')
          ? Math.max(value, merged.piece[key] ?? -Infinity)
          : Math.min(value, merged.piece[key] ?? Infinity);
      });
      return merged;
    },
    { piece: {}, shipment: {} },
  );

  farmapiProduct.piece = Object.assign(farmapiProduct.piece, mergedCountryRules.piece);
  farmapiProduct.shipment = Object.assign(farmapiProduct.shipment, mergedCountryRules.shipment);

  if (config.products_require_ldm_calculation.indexOf(routeState.selectedProduct.value) !== -1) {
    farmapiProduct.piece = Object.assign(
      {},
      farmapiProduct.piece,
      setLoadingMeterAndPalletPlacesValues(farmapiProduct.piece.chargeableWeightMax),
    );
  }

  if (farmapiProduct) {
    // MAX ROWS NUMBER:
    if (farmapiProduct.shipmentRowsNumberMax) {
      shipmentRowsNumberMax = farmapiProduct.shipmentRowsNumberMax;
    }
    commonFarmApiItemDimensions(stateObject, farmapiProduct);
    commonFarmApiTotalDimensions(stateObject, farmapiProduct);
  }
  fillMissingParams(stateObject, dimensionParams, config);
  return [stateObject, shipmentRowsNumberMax];
};

const isValidDimension = value => parseInt(value) >= 0;
