import { Close, Save } from '@mui/icons-material';
import { Alert, Autocomplete, Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { apiLinks } from '../../../config/api-config';
import { apiRequest, getBaseUrl, getTransactionId } from '../../../globals';
import { getPostalCodeRegex } from '../../../globals/helpers/postalcode';
import { fetchCity } from '../../../hooks/api/useGetCity';
import { useConfigCountries } from '../../../hooks/useConfigCoutries';
import { Button } from '../Button';
import PhoneCountryCode from '../PhoneCountryCode';
import { PROFILE_SECTIONS } from './EditProfile';
import { PROFILE_CONSTANTS } from './constants';
import { globalConfig } from '../../../globals/data-config-global';
import { useAppDispatch } from 'store/hooks';
import { rootApi, TagTypes } from 'store/api/apiSlice';
import { PrintLabelSenderAddress } from './EditProfile.types';

export const ContactDetailsForm = ({ userData, setReadOnly }) => {
  const { t } = useTranslation();
  const { accountCountries, companyCountries, companyPhoneCountryCodeOptions } = useConfigCountries();
  const dispatch = useAppDispatch();

  const switchToReadOnly = () => {
    setReadOnly({
      section: PROFILE_SECTIONS.contactDetails,
      readOnly: true,
    });
  };

  const [validatingPostCode, setValidatingPostCode] = useState(false);
  const [postCodeValidationResult, setPostCodeValidationResult] = useState();

  const countryCodePrefix = `${userData.user.phoneNumbers[0]?.number?.split('-')[0]}-`;
  const phoneNumberWithoutPrefix = userData.user.phoneNumbers[0]?.number?.split(countryCodePrefix)[1];

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    getValues,
    setError,
    trigger,
    clearErrors,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      contactName: userData.user.name,
      company: userData.user.companyName,
      companyPhoneCountryCode:
        companyPhoneCountryCodeOptions.find(item => {
          const extractedNumber = userData.user.phoneNumbers[0]?.number?.split('-')[0];
          return item.phone === extractedNumber;
        }) || null,
      companyPhoneNumber: phoneNumberWithoutPrefix,
      companyStreetAddress: userData.user.street,
      companyPostCode: userData.user.postalCode,
      companyCity: userData.user.city,
      companyCountry: companyCountries.find(
        item => item.value.toLowerCase() === userData.user.countryCode?.toLowerCase(),
      ),
      companyVatNumber: userData.user.vatNumber,
      printLabelSenderAddress:
        userData.user.printLabelSenderAddress === null
          ? PrintLabelSenderAddress.DEFAULT
          : userData.user.printLabelSenderAddress,
    },
  });

  const accountCountry = accountCountries.find(
    item => item.value.toLowerCase() === userData.user?.accountCountryCode.toLowerCase(),
  );

  const watchCompanyCity = watch('companyCity');
  const watchCompanyCountry = watch('companyCountry');
  const watchCompanyPhoneCountryCode = watch('companyPhoneCountryCode');
  const watchCompanyPhoneNumber = watch('companyPhoneNumber');
  const watchCompanyPostCode = watch('companyPostCode');
  const watchCompanyStreetAddress = watch('companyStreetAddress');
  const watchSenderAddressOption = watch('printLabelSenderAddress');

  const companyPhoneNumberMaxLength =
    PROFILE_CONSTANTS.companyPhoneNumberLimit - (watchCompanyPhoneCountryCode?.phone?.length || 0);

  const submitEditProfile = data => {
    const submitData = async () => {
      try {
        const phoneNumbers = (data.phoneNumbers = [
          { number: `${data.companyPhoneCountryCode.phone}-${data.companyPhoneNumber}` },
        ]);

        const requestData = {
          // We must provide whole DTO otherwise the omitted props will be reset to default empty values!
          ...userData.user,
          city: data.companyCity,
          companyName: data.company,
          country: data.companyCountry.label,
          countryCode: data.companyCountry.value?.toLowerCase(),
          name: data.contactName,
          phoneNumbers,
          postalCode: data.companyPostCode,
          street: data.companyStreetAddress,
          vatNumber: data.companyVatNumber,
          printLabelSenderAddress:
            data.printLabelSenderAddress === PrintLabelSenderAddress.DEFAULT ? null : data.printLabelSenderAddress,
        };

        const result = await apiRequest(apiLinks.updateUserData, 'PUT', {
          body: requestData,
          headers: {
            Referer: getBaseUrl(null, 1),
            transactionId: getTransactionId('REG'),
          },
        });

        if (result.status === 200 || result.status === 201) {
          dispatch(rootApi.util.invalidateTags([TagTypes.USER_API_TAG]));
          toast(() => (
            <Alert severity="success" variant="standard">
              {t('general|dataSavedSuccessfully')}
            </Alert>
          ));
          switchToReadOnly();
        } else {
          console.error('Error on Edit profile - contact details form - non 200-201 error', result);

          toast(
            <Alert severity="error" variant="standard">
              {t('general|errors|unexpectedErrorText')}
            </Alert>,
          );
        }
      } catch (error) {
        console.error('Error on Edit profile - contact details form - catch error', error);
        toast(
          <Alert severity="error" variant="standard">
            {t('general|errors|unexpectedErrorText')}
          </Alert>,
        );
      }
    };
    return submitData();
  };

  useEffect(() => {
    if (!watchCompanyCity && !watchCompanyPostCode && !watchCompanyStreetAddress && accountCountry) {
      setValue('companyCountry', accountCountry);
    }
  }, [accountCountry, setValue, watchCompanyCity, watchCompanyPostCode, watchCompanyStreetAddress]);

  useEffect(() => {
    if (!watchCompanyPhoneNumber && accountCountry) {
      setValue(
        'companyPhoneCountryCode',
        companyPhoneCountryCodeOptions.find(item => {
          return item.code.toLowerCase() === accountCountry?.value?.toLowerCase();
        }),
      );
    }
  }, [accountCountry, setValue, watchCompanyPhoneNumber, companyPhoneCountryCodeOptions]);

  useEffect(() => {
    if (!watchCompanyPhoneCountryCode && watchCompanyCountry) {
      setValue(
        'companyPhoneCountryCode',
        companyPhoneCountryCodeOptions.find(item => {
          return item.code.toLowerCase() === watchCompanyCountry.value.toLowerCase();
        }),
      );
    }
  }, [watchCompanyPhoneCountryCode, watchCompanyCountry, setValue, companyPhoneCountryCodeOptions]);

  const resolvePostCodeValidation = useCallback(
    ({ clearErrors, getValues, responseFromPostalCode, setError, setValue, trigger, validateCity }) => {
      if (responseFromPostalCode?.data?.validated === false && !responseFromPostalCode?.data?.city) {
        setError('companyPostCode', {
          type: 'value',
        });
        if (getValues('companyCity')) {
          trigger('companyCity');
        }
        return false;
      } else if (responseFromPostalCode?.data?.validated === true) {
        clearErrors('companyPostCode', { required: true });
        if (!getValues('companyCity')) {
          setValue('companyCity', responseFromPostalCode.data.city);
        }
        trigger('companyCity');
        return true;
      } else if (
        responseFromPostalCode?.data?.validated === false &&
        responseFromPostalCode?.data?.city &&
        validateCity
      ) {
        setError('companyCity', {
          type: 'invalidCity',
        });
        return true;
      }
      return true;
    },
    [],
  );

  return (
    <form
      onSubmit={handleSubmit(data => {
        return submitEditProfile(data);
      })}
    >
      <Box
        sx={{
          '& .MuiFormControl-root': {
            mb: '16px',
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="contactName"
              control={control}
              rules={{
                required: true,
                maxLength: PROFILE_CONSTANTS.personNameCharacterLimit,
              }}
              render={({ field }) => {
                const getHelperText = () => {
                  if (errors.contactName) {
                    if (errors.contactName.type === 'maxLength') {
                      return t('general|errors|Invalid maxchar error', {
                        name: t('registration|yourName'),
                        max: PROFILE_CONSTANTS.personNameCharacterLimit,
                      });
                    }
                    return t('general|labels|inputs|required');
                  }
                  return undefined;
                };
                return (
                  <TextField
                    {...field}
                    required
                    label={t('registration|yourName')}
                    sx={{
                      width: '100%',
                    }}
                    onChange={event => {
                      const targetValue = event.target.value;
                      const newValue =
                        targetValue?.length > 0
                          ? targetValue.slice(0, PROFILE_CONSTANTS.personNameCharacterLimit)
                          : targetValue;
                      field.onChange(newValue);
                    }}
                    error={!!errors.contactName}
                    helperText={getHelperText()}
                  />
                );
              }}
            />
            <Controller
              name="company"
              control={control}
              rules={{
                required: true,
                maxLength: PROFILE_CONSTANTS.companyCharacterLimit,
              }}
              render={({ field }) => {
                const getHelperText = () => {
                  if (errors.company?.type === 'maxLength') {
                    return t('general|errors|Invalid maxchar error', {
                      name: t('general|Company'),
                      max: PROFILE_CONSTANTS.companyCharacterLimit,
                    });
                  }
                  if (errors.company) {
                    return t('general|labels|inputs|required');
                  }
                  return undefined;
                };
                return (
                  <TextField
                    {...field}
                    required
                    label={t('general|Company')}
                    sx={{
                      width: '100%',
                    }}
                    onChange={event => {
                      const targetValue = event.target.value;
                      const newValue =
                        targetValue?.length > 0
                          ? targetValue.slice(0, PROFILE_CONSTANTS.companyCharacterLimit)
                          : targetValue;
                      field.onChange(newValue);
                    }}
                    error={!!errors.company}
                    helperText={getHelperText()}
                  />
                );
              }}
            />
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
              }}
            >
              <Controller
                name="companyPhoneCountryCode"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => {
                  return (
                    <PhoneCountryCode
                      field={field}
                      label={t('registration|phoneNumberCountryCode')}
                      onChange={(event, newValue) => {
                        field.onChange(newValue);
                      }}
                      error={!!errors.companyPhoneCountryCode}
                      helperText={errors.companyPhoneCountryCode && t('general|labels|inputs|required')}
                      labelOverlapped={true}
                    />
                  );
                }}
              />
              <Controller
                name="companyPhoneNumber"
                reg
                control={control}
                rules={{
                  required: true,
                  maxLength: companyPhoneNumberMaxLength,
                  /**
                   Matches the following allowed formats:
                    123 123 123
                    123-123 123
                    123-123123
                    208-422-2010
                    208.422.2010
                    208 422 2010
                    12-31 23 123
                    12-31-23-123
                    123.12.31.23
                    (1)2-31-23-123
                    (12)2-31-23-123
                    1/23 123 123
                   */
                  pattern: /^(((\d|\(\d{1,4}\))[ /.-]?)+(\d|\(\d{1,4}\)))*$/,
                }}
                render={({ field }) => {
                  const getError = () => {
                    if (errors.companyPhoneNumber) {
                      if (errors.companyPhoneNumber?.type === 'maxLength') {
                        return t('general|errors|Invalid maxchar error', {
                          name: t('registration|yourPhoneNumber'),
                          max: companyPhoneNumberMaxLength,
                        });
                      }
                      if (errors.companyPhoneNumber?.type === 'required') {
                        return t('general|labels|inputs|required');
                      }
                      if (errors.companyPhoneNumber?.type === 'pattern') {
                      }
                      return t('general|labels|inputs|invalidFormat');
                    }
                    return null;
                  };

                  return (
                    <TextField
                      {...field}
                      onChange={event => {
                        const targetValue = event.target.value;
                        const newValue =
                          targetValue?.length > 0 ? targetValue.slice(0, companyPhoneNumberMaxLength) : targetValue;
                        const noSpacesValue = newValue?.replace(/\s/g, '');
                        field.onChange(noSpacesValue);
                      }}
                      required
                      label={t('registration|yourPhoneNumber')}
                      sx={{
                        width: '100%',
                      }}
                      error={!!errors.companyPhoneNumber}
                      helperText={getError()}
                    />
                  );
                }}
              />
            </Box>
            <Controller
              name="companyVatNumber"
              control={control}
              rules={{
                required: true,
                pattern: globalConfig.regEx.everything,
                maxLength: PROFILE_CONSTANTS.vatNumberCharacterLimit,
              }}
              render={({ field }) => {
                const getError = () => {
                  if (errors.companyVatNumber) {
                    if (errors.companyVatNumber?.type === 'required') {
                      return t('general|labels|inputs|required');
                    }
                    if (errors.companyVatNumber?.type === 'maxLength') {
                      return t('general|errors|Invalid maxchar error', {
                        name: t('general|VAT Number'),
                        max: PROFILE_CONSTANTS.vatNumberCharacterLimit,
                      });
                    }
                    return t('general|labels|inputs|invalidFormat');
                  }
                  return null;
                };

                return (
                  <TextField
                    {...field}
                    required
                    label={t('general|VAT Number')}
                    sx={{
                      width: '100%',
                    }}
                    onChange={event => {
                      const targetValue = event.target.value;
                      const newValue =
                        targetValue?.length > 0
                          ? targetValue.slice(0, PROFILE_CONSTANTS.vatNumberCharacterLimit)
                          : targetValue;
                      field.onChange(newValue);
                    }}
                    error={!!errors.companyVatNumber}
                    helperText={<>{getError()}</>}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="companyStreetAddress"
              control={control}
              rules={{
                required: true,
                maxLength: PROFILE_CONSTANTS.streetAddressCharacterLimit,
              }}
              render={({ field }) => {
                const getHelperText = () => {
                  if (errors.companyStreetAddress) {
                    if (errors.companyStreetAddress.type === 'maxLength') {
                      return t('general|errors|Invalid maxchar error', {
                        name: t('registration|streetAddress'),
                        max: PROFILE_CONSTANTS.streetAddressCharacterLimit,
                      });
                    }
                    return t('general|labels|inputs|required');
                  }
                  return undefined;
                };
                return (
                  <TextField
                    {...field}
                    required
                    label={t('registration|streetAddress')}
                    sx={{
                      width: '100%',
                    }}
                    onChange={event => {
                      const targetValue = event.target.value;
                      const newValue =
                        targetValue?.length > 0
                          ? targetValue.slice(0, PROFILE_CONSTANTS.streetAddressCharacterLimit)
                          : targetValue;
                      field.onChange(newValue);
                    }}
                    error={!!errors.companyStreetAddress}
                    helperText={getHelperText()}
                  />
                );
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="companyPostCode"
                  control={control}
                  rules={{
                    required: true,
                    pattern:
                      watchCompanyCountry?.value &&
                      getPostalCodeRegex({
                        countryCode: watchCompanyCountry.value,
                        accountCountryCode: accountCountry?.value,
                        strict: false,
                      }),
                    maxLength: PROFILE_CONSTANTS.postalCodeCharacterLimit,
                    validate: async (value, formValues) => {
                      const enabledPostalCodeFetch =
                        accountCountry?.value?.toLowerCase() === 'se' && value ? true : false;
                      const validateCity = formValues.companyCountry?.value?.toLowerCase() === 'se';

                      if (enabledPostalCodeFetch) {
                        try {
                          setValidatingPostCode(true);
                          const responseFromPostalCode = await fetchCity({
                            countryCode: formValues.companyCountry?.value,
                            postalCode: value,
                            cityName: validateCity ? formValues.companyCity : undefined,
                            useCustomCountryCode: true,
                            headerCountryCode: accountCountry?.value,
                          });
                          setPostCodeValidationResult(responseFromPostalCode);
                          const isPostCodeValid = resolvePostCodeValidation({
                            clearErrors,
                            getValues,
                            responseFromPostalCode,
                            setError,
                            setValue,
                            trigger,
                            validateCity,
                          });

                          setValidatingPostCode(false);

                          return isPostCodeValid;
                        } catch (error) {
                          setError('companyPostCode', {
                            type: 'server',
                          });
                          setError('companyCity', {
                            type: 'server',
                          });
                          setValidatingPostCode(false);
                          return false;
                        }
                      } else {
                        return true;
                      }
                    },
                  }}
                  render={({ field, fieldState, formState }) => {
                    const getError = () => {
                      if (validatingPostCode) {
                        return (
                          <Box component="span" sx={{ position: 'absolute', top: '17px', right: '10px' }}>
                            {t('general|validating')}
                          </Box>
                        );
                      }
                      if (errors.companyPostCode) {
                        if (errors.companyPostCode?.type === 'maxLength') {
                          return t('general|errors|Invalid maxchar error', {
                            name: t('general|labels|inputs|Postalcode'),
                            max: PROFILE_CONSTANTS.postalCodeCharacterLimit,
                          });
                        }
                        if (errors.companyPostCode?.type === 'server') {
                          return t('general|errors|unableToFetchPostalCodeCity');
                        }
                        if (errors.companyPostCode?.type === 'required') {
                          return t('general|labels|inputs|required');
                        }
                        if (errors.companyPostCode?.type === 'pattern') {
                          return t('general|labels|inputs|invalidFormat');
                        }
                        return t('general|errors|Incorrect Postal Code');
                      }
                      return null;
                    };
                    return (
                      <TextField
                        {...field}
                        onChange={event => {
                          const targetValue = event.target.value;
                          const newValue =
                            targetValue?.length > 0
                              ? targetValue.slice(0, PROFILE_CONSTANTS.postalCodeCharacterLimit)
                              : targetValue;
                          field.onChange(newValue);
                        }}
                        onBlur={() => {
                          trigger('companyPostCode');
                        }}
                        required
                        label={t('general|labels|inputs|Postalcode')}
                        sx={{
                          width: '100%',
                          '& .MuiFormHelperText-root': {
                            m: 0,
                          },
                        }}
                        error={!!errors.companyPostCode}
                        helperText={getError()}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="companyCity"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: PROFILE_CONSTANTS.cityCharacterLimit,
                  }}
                  render={({ field }) => {
                    const getError = () => {
                      if (validatingPostCode) {
                        return (
                          <Box
                            component="span"
                            sx={{
                              position: 'absolute',
                              top: '17px',
                              right: '10px',
                            }}
                          >
                            {t('general|validating')}
                          </Box>
                        );
                      }
                      if (errors.companyCity) {
                        if (errors.companyCity?.type === 'maxLength') {
                          return t('general|errors|Invalid maxchar error', {
                            name: t('general|labels|inputs|City'),
                            max: PROFILE_CONSTANTS.cityCharacterLimit,
                          });
                        }
                        if (errors.companyCity?.type === 'server') {
                          return t('general|errors|unableToFetchPostalCodeCity');
                        }
                        if (errors.companyCity?.type === 'required') {
                          return t('general|labels|inputs|required');
                        } else if (errors.companyCity?.type === 'invalidCity') {
                          return (
                            <span>
                              {t('general|errors|invalidCity')}{' '}
                              <Box
                                component="span"
                                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => {
                                  setValue('companyCity', postCodeValidationResult?.data.city, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                  });
                                }}
                              >
                                {postCodeValidationResult?.data.city}
                              </Box>
                              ?
                            </span>
                          );
                        }
                      }
                      return null;
                    };
                    return (
                      <TextField
                        {...field}
                        onChange={event => {
                          const targetValue = event.target.value;
                          const newValue =
                            targetValue?.length > 0
                              ? targetValue.slice(0, PROFILE_CONSTANTS.cityCharacterLimit)
                              : targetValue;
                          field.onChange(newValue);
                        }}
                        onBlur={e => {
                          if (watchCompanyCountry?.value?.toLowerCase() !== 'se') return;
                          if (!!errors.companyCity) return;
                          const value = e.target.value;
                          if (value === '' && postCodeValidationResult?.data?.city) {
                            setValue('companyCity', postCodeValidationResult.data.city, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                          } else if (value !== '') {
                            trigger('companyPostCode');
                          }
                        }}
                        required
                        label={t('general|labels|inputs|City')}
                        sx={{
                          width: '100%',
                          '& .MuiFormHelperText-root': {
                            m: 0,
                          },
                        }}
                        error={!!errors.companyCity}
                        helperText={getError()}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Controller
              name="companyCountry"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={companyCountries}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    clearText=""
                    getOptionLabel={option => t(`${option.translationKey}`)}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    sx={{ width: '100%' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={t('registration|companyCountry')}
                        error={!!errors.companyCountry}
                        helperText={errors.companyCountry && t('general|labels|inputs|required')}
                      />
                    )}
                  />
                );
              }}
            />

            <Controller
              name="printLabelSenderAddress"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return (
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-autowidth-label">
                      {t('editProfile|AddressFromPrintedOnLabel')}
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      onChange={(event, newValue) => {
                        field.onChange(event.target.value);
                      }}
                      label={t('editProfile|AddressFromPrintedOnLabel')}
                    >
                      <MenuItem value={PrintLabelSenderAddress.DEFAULT}>
                        {t('editProfile|PrintSenderAddressDefault')}
                      </MenuItem>
                      <MenuItem value={PrintLabelSenderAddress.CONTACT_ADDRESS}>
                        {t('editProfile|PrintMyContactAddress')}
                      </MenuItem>
                      <MenuItem value={PrintLabelSenderAddress.NO_ADDRESS}>
                        {t('editProfile|KeepSourceAddressBlank')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                );
              }}
            />
            {watchSenderAddressOption !== PrintLabelSenderAddress.DEFAULT && (
              <Box
                sx={{
                  color: 'red',
                }}
              >
                {t('editProfile|AddressFromPrintedOnLabelWarrningNote')}
              </Box>
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: '16px',
            gap: '16px',
          }}
        >
          <Button
            type="submit"
            loading={isSubmitting}
            variant="contained"
            color="primary"
            startIcon={<Save />}
            dataTestId="saveBtn"
          >
            {t('general|labels|buttons|Save')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              switchToReadOnly();
            }}
            startIcon={<Close />}
            dataTestId="cancelBtn"
          >
            {t('general|labels|buttons|Cancel')}
          </Button>
          {Object.keys(errors).length > 0 ? (
            <Alert severity="error" variant="standard" sx={{ p: '0 12px' }}>
              {t('registration|invalidData')}
            </Alert>
          ) : null}
        </Box>
      </Box>
    </form>
  );
};
