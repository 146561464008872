export type ReadOnlySectionType = 'contactDetails' | 'emailPassword' | 'printSettings' | 'shipmentTypes';

export type SetReadOnlySectionFunc = ({
  section,
  readOnly,
}: {
  section: ReadOnlySectionType;
  readOnly: boolean;
}) => void;
export enum PrintLabelSenderAddress {
  DEFAULT = 'DEFAULT',
  CONTACT_ADDRESS = 'CONTACT_ADDRESS',
  NO_ADDRESS = 'NO_ADDRESS',
}
